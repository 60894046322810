import { graphql } from "gatsby"
import React from "react"
import OurWorkPage from '../../components/pages/OurWork'

const lang = 'ca'

const OurWork = ({ data, pageContext }) => {
  const { seoDataJson } = data
  const seoData = {
    url: seoDataJson.url_ca,
    titleSeo: seoDataJson.title_ca,
    description: seoDataJson.description_ca,
    alternateLanguage: seoDataJson.alternateLanguage_ca,
    alternateUrl: seoDataJson.alternateUrl_ca,
  }

  const [resultUrls] = pageContext.urls.filter(obj => {
    return obj.id === "OUR-WORK"
  })

  return (
    <OurWorkPage
      lang={lang}
      data={data}
      seoData={seoData}
      urls={resultUrls}
    />
  )
}

export const imagesOurWork = graphql`
  query imagesOurWorkCa {
    allFile(filter: { ext: { regex: "/jpg|png/" } }) {
      nodes {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        extension
        publicURL
      }
    }
    logos: allFile(filter: { extension: { eq: "svg" } }) {
      nodes {
        extension
        publicURL
      }
    }
    allProjectsJson(filter: { lang: { eq: "ca" } }) {
      edges {
        node {
          name
          fileName
          description
          sideChange
          logo
          lang
          sendTo
        }
      }
    }
    seoDataJson(name: { eq: "our-work" }) {
      url_ca
      title_ca
      description_ca
      alternateLanguage_ca
      alternateUrl_ca
    }
  }
`

export default OurWork
