import React from "react"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import Layout from "../../Layout/Layout"
import PortfolioCard from "../../portfolio-card/portfolioCard"
import styles from "../../../styles/ourWork.module.scss"
import WhiteSection from "../../whiteSection/whiteSection"
import tt from "../../../helpers/translation"


const OurWorKPage = ({ data, lang, seoData, urls }) => {
  const portfolioMap = data => {
    return data.allProjectsJson.edges.map(element => (
      <PortfolioCard
        key={element.node.fileName}
        fotos={data.allFile.nodes}
        name={element.node.name}
        fileName={element.node.fileName}
        description={element.node.description}
        sideChange={element.node.sideChange}
        logo={element.node.logo}
        logos={data.logos}
        sendTo={element.node.sendTo}
        textButton={tt("Case study", lang)}
      />
    ))
  }

  return (
    <Layout
      leftContactUs
      lan={lang}
      location="ourwork"
      contrast={true}
      seoData={seoData}
      urls={urls}
    >
      <WhiteSection
        first
        firstTitle={tt("Case studies", lang)}
        subtitle={
          tt("We design  and build powerful platforms for all type of businesses. Check out some of our recent work", lang)
        }
      />
      <div className={styles.portfolio_section_container}>
        {portfolioMap(data)}
      </div>
      <div className={`${styles.blue_container}`}>
        <h2>{tt('Other Clients', lang)}</h2>
        <ul>
          <li>Barceló Hoteles & Resorts</li>
          <li>Puig</li>
          <li>Ajuntament de Barcelona</li>
          <li>Vueling</li>
        </ul>
      </div>
    </Layout>
  )
}

export default OurWorKPage
