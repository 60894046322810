import { Link } from "gatsby"
import React, { useState } from "react"
import Slide from "react-reveal/Slide"
import PropTypes from "prop-types"
import styles from "./index.module.scss"
import Image from "gatsby-image"
import { SeeMoreButton } from "../seeMoreButton/seeMoreButton"
import LogoCopy from "../../assets/logo-copy.svg"

const PortfolioCard = ({
  fotos,
  name,
  fileName,
  description,
  logos,
  logo,
  sideChange,
  textButton,
  sendTo,
}) => {
  const { fluid } = fotos.find(
    foto => foto.childImageSharp.fluid.src.indexOf(fileName) > -1
  ).childImageSharp
  const fluidSvg = logos.nodes.find(
    unilogo => unilogo.publicURL.indexOf(logo) > -1
  ).publicURL

  return (
    <div className={styles.wrapper} key={name}>
      <Image
        alt=""
        className={`${styles.image} ${sideChange ? styles.left : ""}`}
        fluid={fluid}
      />
      <Slide left={!sideChange} right={sideChange}>
        <div
          className={`${styles.text_box}  ${sideChange ? styles.right : ""}`}
        >
          <img src={fluidSvg} />
          {/* <h2>{name}</h2> */}
          <p>{description}</p>
          <SeeMoreButton
            text={textButton}
            contrast={true}
            sendTo={sendTo || undefined}
          />
        </div>
      </Slide>
    </div>
  )
}

PortfolioCard.propTypes = {
  name: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  fotos: PropTypes.array.isRequired,
  logo: PropTypes.string,
  sideChange: PropTypes.bool,
  textButton: PropTypes.string,
  sendTo: PropTypes.string,
}

PortfolioCard.defaultProps = {
  logo: <LogoCopy />,
  sideChange: false,
  textButton: undefined,
  sendTo: undefined,
}

export default PortfolioCard
