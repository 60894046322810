import React from "react"
import Slide from "react-reveal/Slide"
import styles from "./whiteSection.module.scss"
import { SeeMoreButton } from "../seeMoreButton/seeMoreButton"

const WhiteSection = ({
  firstTitle,
  title,
  subtitle,
  text,
  children,
  buttonText,
  first,
  ref,
  careersClass,
}) => {
  let textWithLines = text
  if (typeof text === "string") {
    textWithLines = text.split("\n").map((line, i) => (
      <p className={styles.description} key={line}>
        {line}
      </p>
    ))
  }
  const main = (
    <div
      className={`${first ? styles.first : null} ${styles.text_container} ${
        careersClass ? styles.career : null
      }`}
    >
      {firstTitle ? <h1>{firstTitle}</h1> : title ? <h2>{title}</h2> : null}
      {firstTitle && first ? (
        <div className={styles.subtitle_p_container}>
          <h2 className={styles.h2_of_a_first}>{subtitle}</h2>
        </div>
      ) : (
        <div className={styles.subtitle_p_container}>
          <p className={styles.subtitle}>{subtitle}</p>
          {text ? (
            <div className={styles.content_text_continer}>
              {textWithLines}
              {/* <div className={styles.fadingEffect} /> TODO */}
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
      {buttonText ? <SeeMoreButton contrast text={buttonText} /> : <></>}
      {children || <></>}
    </div>
  )
  if (first) {
    return (
      <div
        ref={ref}
        className={`${first ? styles.first : null} ${
          styles.white_section_container
        }`}
      >
        <div className={styles.mobile}>{main}</div>
        <div className={styles.desktop}>
          <Slide left>{main}</Slide>
        </div>
      </div>
    )
  }
  return (
    <div
      ref={ref}
      className={`${first ? styles.first : null} ${
        styles.white_section_container
      }`}
    >
      <Slide left>{main}</Slide>
    </div>
  )
}

export default WhiteSection
